import HttpService from "../HttpService";

export const order = async (data) => {
    const res = await HttpService({
        method: 'POST',
        url: '/api/v2/cart',
        data
    });
    return res.data;
};

export const resendVerification = async () => {

    const res = await HttpService({
        method: 'PUT',
        url: '/api/v2/members/resend'
    });

    return res.data;
};

export const paymentOldCard = async ({ id, data }) => {

    const res = await HttpService({
        method: 'PUT',
        url: '/api/v2/group_orders/' + id + '/complete',
        data
    });

    return res.data;
};

export const cancelGroupOrder = async (id) => {

    const res = await HttpService({
        method: 'DELETE',
        url: '/api/v2/group_orders/' + id
    });

    return res.data;
};

export const fetchGroupOrders = async (slug) => {

    const res = await HttpService({
        method: 'GET',
        url: '/api/v2/group_orders/' + slug,
    });

    return res.data;
};

export const paymentCompleteGroupOrders = async (data) => {

    const res = await HttpService({
        method: 'PUT',
        url: '/api/v2/group_orders/' + data.id + '/complete',
        data: data.data
    });

    return res.data;
}

export const uploadCSV = async ({ id, csv_file }) => {
    const res = await HttpService({
        method: 'POST',
        type: 'multipart/form-data',
        url: '/api/v2/group_orders/' + id + '/invite_members',
        data: { csv_file }
    });

    return res.data;
};

export const setupIntentGroupOrder = async ({ id, group_order }) => {
    const res = await HttpService({
        method: 'PUT',
        url: '/api/v2/group_orders/' + id + '/setup_intent',
        data: {
            group_order
        }
    });

    return res.data;
};
export const updateGroupOrder = async ({ id, group_order }) => {
    const res = await HttpService({
        method: 'PUT',
        url: '/api/v2/group_orders/' + id,
        data: {
            group_order
        }
    });

    return res.data;
};

export const getGroupOrderItems = async (id) => {

    const res = await HttpService({
        method: 'GET',
        url: '/api/v2/group_orders/' + id + '/group_order_items',
    });

    return res.data;
};

export const getMyMember = async () => {

    const res = await HttpService({
        method: 'GET',
        url: '/api/v2/member',
    });

    return res.data;
};