import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker/es";
import 'react-datepicker/src/stylesheets/datepicker.scss';

const FormData = ({ disabled, minDate, name, value: dateValue, maxDate, setFieldValue, avoidDefault }) => {
    const value = dateValue instanceof Date ? dateValue : '';
    const [startDate, setStartDate] = useState(value);
    useEffect(() => {
        if (!avoidDefault && minDate && value && new Date(value).valueOf() < minDate) {
            setStartDate(new Date(minDate));
            setFieldValue(name, new Date(minDate))
        } else {
            setStartDate(value);
        }
    }, [value])
    const handler = (value) => {
        setStartDate(value)
        setFieldValue(name, value)
    }
    return <DatePicker disabled={disabled} maxDate={maxDate} minDate={minDate} autoComplete='off' name={name} selected={startDate} onChange={handler} />
}
export default FormData