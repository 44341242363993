import HttpService from "../HttpService";

export const addToken = async ({id, first_name, last_name, email, phone, selected_delivery_option, dietary_types, profile_image}) => {

    const formData = new FormData();

    formData.append("member[first_name]", first_name);
    formData.append("member[last_name]", last_name);
    formData.append("member[email]", email);
    formData.append("member[phone]", phone);
    selected_delivery_option && formData.append("member[selected_delivery_option]", selected_delivery_option);
    profile_image && formData.append("member[profile_image]", profile_image);
    dietary_types && dietary_types.forEach(item => formData.append("member[dietary_types][]", item))

    const res = await HttpService({
        method: 'POST',
        type: 'multipart/form-data',
        formData: true,
        url: '/api/v2/group_orders/' + id + '/members/',
        data: formData
    });

    return res.data;
};
export const signUpDashboard = async ({id, first_name, last_name, email, phone, selected_delivery_option, dietary_types, profile_image}) => {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("phone", phone);
    selected_delivery_option && formData.append("selected_delivery_option", selected_delivery_option);
    profile_image && formData.append("profile_image", profile_image);
    dietary_types && dietary_types.forEach(item => formData.append("dietary_types[]", item))

    const res = await HttpService({
        method: 'POST',
        type: 'multipart/form-data',
        formData: true,
        url: '/api/v2/dashboard_signup',
        data: formData
    });

    return res.data;
};

export const editProfile = async ({first_name, last_name, email, phone, selected_delivery_option, dietary_types, profile_image}) => {

    const formData = new FormData();

    formData.append("member[first_name]", first_name);
    formData.append("member[last_name]", last_name);
    formData.append("member[email]", email);
    formData.append("member[phone]", phone);
    selected_delivery_option && formData.append("member[selected_delivery_option]", selected_delivery_option);
    profile_image && formData.append("member[profile_image]", profile_image);
    dietary_types && dietary_types.forEach(item => formData.append("member[dietary_types][]", item))

    const res = await HttpService({
        method: 'PUT',
        formData: true,
        type: 'multipart/form-data',
        url: '/api/v2/members',
        data: formData
    });

    return res.data;
};

export const logIn = async ({id, phone}) => {

    const res = await HttpService({
        method: 'POST',
        url: '/api/v2/group_orders/' + id + '/signin/',
        data: {phone}
    });

    return res.data;
};
export const dashboardLogin = async (data) => {
    const res = await HttpService({
        method: 'POST',
        url: '/api/v2/dashboard_signin',
        data
    });
    return res.data;
};

export const addTeamOrder = async (data) => {

    const res = await HttpService({
        method: 'POST',
        url: '/api/v2/team_order',
        data
    });

    return res.data;
};

export const verification = async (data) => {

    const res = await HttpService({
        method: 'PUT',
        url: '/api/v2/members/verify',
        data
    });

    return res.data;
};