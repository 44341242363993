import * as CommonService from "./Api/CommonService";
import * as AuthService from "./Api/AuthService";
import * as FormService from "./Api/FormService";
import * as ProductService from "./Api/ProductService";
import * as GroupOrderDashoard from "./Api/GroupOrderDashoard";

const ApiService = {
    auth: AuthService,
    common: CommonService,
    product: ProductService,
    groupOrderDashboard: GroupOrderDashoard,
    form: FormService,
    SERVER: 'server',
    CLIENT: 'client',
}

export default ApiService
