import Axios from 'axios';
import { toast } from "react-toastify";
import { history } from "../utils/helpers";

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

const defaultConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true', // Add this header
    },
};

const client = Axios.create(defaultConfig);
const server = Axios.create(defaultConfig);

const interceptorClient = (config) => {

    const token = localStorage.getItem('token');
    const GroupOrderMemberID = localStorage.getItem('GroupOrderMemberID');
    if (document && token) {
        config.headers.Authorization = token;
    }
    if (GroupOrderMemberID) {
        config.headers.GroupOrderMemberID = GroupOrderMemberID
    }

    if (config.method === 'post' || config.method === 'put') {
        if (config.type) {
            config.headers = {
                ...config.headers,
                'Content-Type': config.type
            }
            if (!config.formData) {
                config.data = jsonToFormData(config.data);
            }
        }
    }
    return config;
}

client.interceptors.request.use(interceptorClient);

client.interceptors.response.use(
    function eventResponse(response) {
        if (response.data.status !== undefined && response.data.status === false) {
            // throw new FormInvalidFieldException(response.data);
        }
        return response
    },
    function eventError(error) {
        if (error?.response.status === 422 || error?.response.status === 401) {
            if (error?.response?.data?.error) {
                toast(error?.response?.data?.error, { type: 'error' });
            }
            throw new ApiFormInvalidFieldException(error?.response.data)
        }
        if (error?.response.status === 404) {
            history.push('/error');
        }
        if (error?.response.status > 499) {
            throw Error('серверная ошибка')
        }
        if (error?.response.status !== 200) {
            throw new ApiRequestFailed()
        }
        return Promise.reject(error?.response?.data);
    });

function commonClient(config, type = 'client') {
    const clients = {
        client: client,
        server: server
    }
    return clients[type](config)
}

export function ApiRequestFailed() { }
export function ApiFormInvalidFieldException(value) {
    this.message = value.error
}

export { client };

export default commonClient;
