import { GROUP_ORDER_DASHBOARD_USER_DATA, GROUP_ORDER_DATA, IS_LOADING_GROUP_ORDER, UPDATE_GROUP_ORDER_ITEM } from "./GroupOrder.constant";
export const initialState = {
    groupOrderData: [],
    userData: {},
    isLoading: false,
}

const groupOrderDashboard = (state = initialState, action) => {
    switch (action.type) {
        case GROUP_ORDER_DATA:
            return {
                ...state,
                groupOrderData: action.payload
            }
        case GROUP_ORDER_DASHBOARD_USER_DATA:
            return {
                ...state,
                userData: action.payload
            }
        case UPDATE_GROUP_ORDER_ITEM:
            return {
                ...state,
                groupOrderData: state.groupOrderData.map((item) => {
                    if (item?.id == action.payload?.id) {
                        return {
                            ...item,
                            joined: action.payload?.joined
                        }
                    }
                    return item;
                })
            }
        case IS_LOADING_GROUP_ORDER:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}

export default groupOrderDashboard;
