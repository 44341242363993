import React from "react";
import FormInputSelect from "../FormInput/FormInputSelect";
import times from "../../../../mock/times";
import { format } from "date-fns";

const FormTime = ({ name, minDate, value, maximumDeadlineTime, maxDate, selectedDateValue, ...props }) => {
  const formatterDate = (val, forates) =>
    format(new Date(val), forates).toUpperCase();

  let realTime;
  if (
    formatterDate(minDate, "mm") - (formatterDate(minDate, "mm") % 15) ===
    45
  ) {
    let house =
      +formatterDate(minDate, "h") === 12
        ? 1
        : +formatterDate(minDate, "h") + 1;
    realTime = house + ":00" + formatterDate(minDate, " aaa");
  } else {
    realTime =
      formatterDate(minDate, "h:") +
      (formatterDate(minDate, "mm") -
        (formatterDate(minDate, "mm") % 15) +
        15) +
      formatterDate(minDate, " aaa");
  }
  const indexTimes = times.indexOf(realTime);
  const timeToMinutes = (time) => {

    const [hourMin, period] = time.split(' ');
    let [hour, minute] = hourMin.split(':').map(Number);
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    return hour * 60 + minute;
  }
  if (maximumDeadlineTime) {
    const providedTimeInMinutes = timeToMinutes(maximumDeadlineTime?.toString());
    var filteredTimes = times.filter(time => timeToMinutes(time) <= providedTimeInMinutes);
  }
  return (
    <FormInputSelect
      {...props}
      minDate={minDate}
      select={true}
      classContainer="time__picker"
      name={name}
      data={
        value && maximumDeadlineTime ?
          (formatterDate(maxDate, "MMMM dd, EEEE") == formatterDate(selectedDateValue, "MMMM dd, EEEE") ? filteredTimes : times) :
          value &&
            formatterDate(value, "MMMM dd, EEEE") ===
            formatterDate(minDate, "MMMM dd, EEEE") &&
            indexTimes !== -1
            ? times.slice(indexTimes)
            : times
      }
    />
  );
};
export default FormTime;
