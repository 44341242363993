import React, { useState } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";

const FormSubmit = ({ label, children, className, disabled = false }) => {
  const buttonClassName = className ? className : "btn btn-yellow";
  const [current, setCurrent] = useState(false);
  const formikContext = useFormikContext();
  const text =
    formikContext.isSubmitting && current ? "Loader ..." : children || label;
  return (
    <button
      name="submit"
      type="submit"
      className={cn(
        { "btn-loading": formikContext.isSubmitting },
        buttonClassName
      )}
      disabled={formikContext.isSubmitting || disabled}
      onClick={() => setCurrent(true)}
    >
      {text}
    </button>
  );
};

export default FormSubmit;
