import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { FORM_APPLY_CODE } from "../../../../store/Form/Form.constant";
import FormInput from "../../../../modules/ui/Form/FormInput";
import StripeElement from "../../../../modules/ui/StripeElement";
import {
  COMMON_TOGGLE_LOADING,
  PAYMENT_OLD_CARD_INTENT,
} from "../../../../store/Common/Common.constant";
import cn from "classnames";
import Loader from "../../../../modules/ui/Loader";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import FormSelect from "../../../../modules/ui/Form/FormSelect";
import FormCheckbox from "../../../../modules/ui/Form/FormCheckbox";

const PromoField = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  const ApplyCode = () => {
    values.promoCode &&
      dispatch({ type: FORM_APPLY_CODE, payload: values.promoCode });
  };
  return (
    <>
      <div className="promo__field">
        <FormInput
          withoutContainer={true}
          name="promoCode"
          placeholder="Have a promo code?"
        />
        <button onClick={ApplyCode} type="button">
          Apply
        </button>
      </div>
      <p>Your promo code will be reflected on your invoice.</p>
    </>
  );
};

const CheckoutForm = ({
  error,
  uuid,
  edit,
  pathname,
  id,
  loading,
  currency,
  stripeInfo,
  orderHandler,
  stripeErrorMessage,
  setStripeErrorMessage,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const addNewCard = async () => {
    dispatch({ type: COMMON_TOGGLE_LOADING, payload: true });

    if (!stripe || !elements) {
      return;
    }
    try {
      const result = await stripe.confirmCardSetup(stripeInfo.client_secret, {
        payment_method: {
          card: elements.getElement("cardNumber"),
        },
      });
      orderHandler(stripeInfo, result);
    } catch (err) {
      orderHandler(stripeInfo, err);
    }
  };

  const handleSubmit = () => {
    if (values?.selected_payment) {
      values.selected_payment === "add"
        ? addNewCard()
        : dispatch({
          type: PAYMENT_OLD_CARD_INTENT,
          payload: {
            uuid: uuid,
            redirect: pathname + "#success",
            id: id,
            data: { payment_method_id: values?.selected_payment },
          },
        });
    } else setStripeErrorMessage("Payment method not selected");
  };
  let infoPrice = stripeInfo?.price_info
    ? {
      head_count: values?.head_count,
      per_person_budget: (+values?.per_person_budget / 100).toFixed(2),
      sub_total: (+stripeInfo?.price_info?.sub_total / 100).toFixed(2),
      delivery_fee: (+stripeInfo?.price_info?.delivery_fee / 100).toFixed(2),
      tax_rate: (+stripeInfo?.price_info?.tax_rate * 100).toFixed(2),
      tax_amount: (+stripeInfo?.price_info?.tax_amount / 100).toFixed(2),
      total: (+stripeInfo?.price_info?.total / 100).toFixed(2),
    }
    : {};

  let options = stripeInfo?.saved_cards?.map((item) => ({
    value: item.payment_method_id,
    label: item.name,
  }));
  options && options.push({ value: "add", label: "Add new card" });

  const optionsOne = () => {
    if (options && options?.length === 1 && !values.selected_payment) {
      setFieldValue("selected_payment", "add");
      setFieldValue("save_card", true);
    }
  };
  useEffect(optionsOne, [options]);

  const onChangeSelect = (e) => {
    if (e.value === "add") {
      setFieldValue("save_card", true);
    } else {
      setFieldValue("save_card", false);
    }
  };

  useEffect(() => {
    const index = stripeInfo?.saved_cards?.findIndex(item => item.is_default)
    if (index !== -1) {
      setFieldValue("selected_payment", stripeInfo?.saved_cards[index]?.payment_method_id)
    }
  }, [stripeInfo?.saved_cards, values])

  return (
    <>
      <div className="pay__info">
        <div className={cn("pay-info__wrap", { loading: loading })}>
          <Loader loading={loading} />
          <div className="pay-info__header">
            <div className="payInfo-header__title">
              Headcount: {infoPrice.head_count ? infoPrice.head_count : 0} x{" "}
              {currency}
              {infoPrice.per_person_budget
                ? (infoPrice.per_person_budget * 100).toFixed(2)
                : 0}{" "}
              Budget
            </div>
            <div className="payInfo-header__price">
              {currency}
              {infoPrice.sub_total ? infoPrice.sub_total : 0}
            </div>
          </div>
          <ul className="pay-info__content">
            <li>
              <span>Subtotal</span>
              <span>{infoPrice.sub_total ? infoPrice.sub_total : 0}</span>
            </li>
            <li>
              <span>Delivery</span>
              <span>
                {currency}
                {infoPrice.delivery_fee ? infoPrice.delivery_fee : 0}
              </span>
            </li>
            <li>
              <span>Tax ({infoPrice.tax_rate ? infoPrice.tax_rate : 0}%)</span>
              <span>
                {currency}
                {infoPrice.tax_amount ? infoPrice.tax_amount : 0}
              </span>
            </li>
          </ul>
          <div className="pay-info__footer">
            <div className="payInfo-footer__wrap">
              <div className="payInfo-footer__title">
                Pre-Auth Total: {currency}
                {infoPrice.total ? infoPrice.total : 0}
              </div>
              <div className="payInfo-footer__note">
                Note: The final order total may very based on the number of
                participants who place an order.
              </div>
            </div>
          </div>
        </div>
        <div className="hidden__content float__field">
          {options?.length !== 1 ? (
            <FormSelect
              onChange={onChangeSelect}
              options={options}
              name="selected_payment"
              title="Pay with"
            />
          ) : null}
          {values?.selected_payment === "add" || options?.length === 1 ? (
            <div className="card__group">
              <StripeElement card="CardNumberElement" title="Card number" />
              <div className="card__double--info">
                <StripeElement card="CardExpiryElement" title="Expiry Date" />
                <StripeElement card="CardCvcElement" title="CSC Code" />
              </div>
            </div>
          ) : null}
        </div>
        {values.selected_payment === "add" ? (
          <div className="form__check">
            <ul>
              <li>
                <FormCheckbox
                  name="save_card"
                  title="By checking this box you agree to save your card for later. This will speed up your checkout process."
                />
              </li>
            </ul>
          </div>
        ) : null}
        {stripeErrorMessage ? (
          <div className="form__error">{stripeErrorMessage}</div>
        ) : null}
        <div className="promo__input">
          <PromoField />
        </div>
      </div>
      <div className="form__submit">
        <button disabled={loading} onClick={handleSubmit} type="button">
          Continue
        </button>
      </div>
    </>
  );
};
export default CheckoutForm;
