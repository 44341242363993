import React, { useState } from "react";

const ImagePreview = ({ image, defaultImage, lazy = true, alt, ...props }) => {
    const [error, setError] = useState('')
    return (
        <>
            {image
                ? typeof (image) === "object"
                    ? image.retina
                        ? <img onError={setError} alt={alt} {...props} loading={lazy ? "lazy" : 'eager'} src={image.base} srcSet={`${image.base} 1x, ${image.retina} 2x`} />
                        : <img onError={setError} alt={alt} {...props} loading="lazy" src={image.base} />
                    : <img onError={setError} alt={alt} {...props} loading="lazy" src={error ? defaultImage : image} />
                : null
            }
        </>
    )
}
export default ImagePreview