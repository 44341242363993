export const DELETE_PRODUCT_MY_ORDER = "@groupOrder/DELETE_GROUP_ORDER";
export const GROUP_ORDER_DATA = "@groupOrder/GROUP_ORDER_DATA";
export const GROUP_ORDER_DASHBOARD_USER_DATA = '@groupOrder/GROUP_ORDER_DASHBOARD_USER_DATA'
export const UPDATE_GROUP_ORDER_ITEM = "@groupOrder/UPDATE_GROUP_ORDER_ITEM";
export const JOINED_GROUP_ORDER="@groupOrder/JOINED_GROUP_ORDER"
export const FETCH_GROUP_ORDER_DASHBOARD =
  "@product/FETCH_GROUP_ORDER_DASHBOARD";
export const IS_LOADING_GROUP_ORDER = "@groupOrder/IS_LOADING_GROUP_ORDER";
export const DASHBOARD_SIGNING_GROUP_ORDER =
  "@product/DASHBOARD_SIGNING_GROUP_ORDER";
