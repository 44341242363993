import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import Card from "./card";
import { FETCH_GROUP_ORDER_DASHBOARD } from "../../store/GroupOrder/GroupOrder.constant";
import AnimatedLoader from "../../modules/ui/animatedLoader";
import ModalAuth from "../../modules/ui/Modals/ModalAuth";
import ModalConfirmation from "../../modules/ui/Modals/ModalСonfirmation";
import {
  CLOSE_MODAL_AUTH,
  MODAL_TOGGLE,
  NEW_USER_MODAL,
} from "../../store/Modal/Modal.constant";
import { WidgetHeader } from "../../widgets";
import ModalEditProfile from "../../modules/ui/Modals/ModalEditProfile";

const GroupOrder = ({ match }) => {
  const { slug_uuid } = match?.params || {};
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { groupOrderData, isLoading } = useSelector(
    (state) => state.groupOrderDashboard
  );
  const company_name =
    (Array.isArray(groupOrderData) &&
      groupOrderData[0]?.creator_company_name) ||
    "";
  const joinedGrupOrders = "joined_group_orders";
  const upcomingGroupOrders = "active";
  const yetToJoinGroupOrders = "yet_to_join_group_orders";
  const [selectedFilters, setSelectedFilters] = useState(upcomingGroupOrders);
  const common = useSelector((state) => state.common);
  const settings = common.settings?.[slug_uuid];
  const form = useSelector((state) => state.form);
  const modals = useSelector((store) => store.modal[slug_uuid]);
  const groupOrders = common.groupOrders?.[slug_uuid];

  const handleFilterChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters(checked ? value : null);
  };
  useEffect(() => {
    const key = localStorage.getItem("GroupOrderMemberID");
    const GroupOrderMemberID = key == "undefined" ? null : JSON.parse(key);
    if (typeof GroupOrderMemberID !== "number") {
      localStorage.setItem("GroupOrderMemberID", null);
    }
  }, []);
  const fetchData = async () => {
    const token = await localStorage.getItem("token");
    if (token) {
      dispatch({
        type: FETCH_GROUP_ORDER_DASHBOARD,
        payload: { id: slug_uuid, filter: selectedFilters },
      });
    }
  };
  useEffect(() => {
    if (settings?.auth?.auth_token !== token) {
      dispatch({ type: NEW_USER_MODAL, payload: slug_uuid });
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [selectedFilters, token]);
  const selectedFilterLable = useMemo(() => {
    switch (selectedFilters) {
      case upcomingGroupOrders:
        return "Upcoming Group Orders";
      case yetToJoinGroupOrders:
        return "Yet To Join Group Orders";
      case joinedGrupOrders:
        return "Joined Group Orders";
      default:
        return "";
    }
  }, [selectedFilters]);
  return (
    <>
      <AnimatedLoader isLoading={isLoading} />
      <WidgetHeader user_id={slug_uuid} isJoin={true} auth={settings?.auth} />
      {modals?.isModalAuth ? (
        <ModalAuth
          user_id={slug_uuid}
          error={form.form?.error?.message}
          show={modals.isModalAuth}
          onHide={() =>
            dispatch({ type: CLOSE_MODAL_AUTH, payload: slug_uuid })
          }
        />
      ) : null}
      {modals?.isModalConfirmation ? (
        <ModalConfirmation
          user_id={slug_uuid}
          isResendCode={settings?.isResendCode}
          error={form.form?.error?.message}
          show={modals.isModalConfirmation}
          order_deadline={groupOrders?.order_deadline * 1000}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalConfirmation", user_id: slug_uuid },
            })
          }
        />
      ) : null}
      {modals?.isModalEditProfile ? (
        <ModalEditProfile
          error={form.form?.error?.message}
          user_id={slug_uuid}
          groupOrders={groupOrders}
          show={modals.isModalEditProfile}
          auth={settings?.auth}
          onHide={() =>
            dispatch({
              type: MODAL_TOGGLE,
              payload: { data: "isModalEditProfile", user_id: slug_uuid },
            })
          }
        />
      ) : null}

      <div className="banner">
        <img src="/img/banner.webp" />
        <span className="group-name">{company_name} Group Order DashBoard</span>
      </div>
      <div className="container px-0 group-order-container">
        <div className="d-flex justify-content-between align-items-center">
          <h3>{selectedFilterLable}</h3>

          <div className="d-flex justify-content-between">
            <form className="form-flex">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="vehicle4"
                  name="vehicle4"
                  value={upcomingGroupOrders}
                  checked={selectedFilters === upcomingGroupOrders}
                  onChange={handleFilterChange}
                />
                <label htmlFor="vehicle4">Upcoming Group Orders</label>
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="vehicle3"
                  name="vehicle3"
                  value={joinedGrupOrders}
                  checked={selectedFilters === joinedGrupOrders}
                  onChange={handleFilterChange}
                />
                <label htmlFor="vehicle3">Joined group orders</label>
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="vehicle4"
                  name="vehicle4"
                  value={yetToJoinGroupOrders}
                  checked={selectedFilters === yetToJoinGroupOrders}
                  onChange={handleFilterChange}
                />
                <label htmlFor="vehicle4">Yet To Join Group Orders</label>
              </div>
            </form>
          </div>
        </div>
        {groupOrderData && groupOrderData.length > 0 ? (
          <div className="group-cards">
            {groupOrderData.map((item) => (
              <Card key={item.id} {...item} />
            ))}
          </div>
        ) : (
          <h3
            style={{ height: "50vh", opacity: ".6" }}
            className="text-center opacity-3 d-flex justify-content-center align-items-center"
          >
            Oops! No group orders have been added here, yet
          </h3>
        )}
      </div>
    </>
  );
};

export default withRouter(GroupOrder);
