import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import Invitation from "./Invitation";
import MyOrder from "./MyOrder";
import TopTabs from "./TopTabs";
import { useDispatch, useSelector } from "react-redux";
import {
  COMMON_CHANGE_BUDGET,
  COMMON_CLEAR_OLD_GO,
  COMMON_GET_MY_MEMBER,
  FETCH_GROUP_ORDER,
  FETCH_GROUP_ORDER_ITEMS,
} from "../../store/Common/Common.constant";
import { withRouter } from "react-router-dom";
import Modals from "../../modules/ui/Modals";
import {
  FETCH_OLD_MY_PRODUCTS,
  NEW_USER_PRODUCTS,
  PRODUCTS_CLEAR_OLD_GO,
} from "../../store/Products/Products.constant";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CLOSE_MODAL_AUTH,
  MODAL_CLEAR_OLD_GO,
  MODAL_TOGGLE,
  NEW_USER_MODAL,
} from "../../store/Modal/Modal.constant";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { WidgetFooter, WidgetHeader } from "../../widgets";
import { FORM_CLEAR_OLD_GO } from "../../store/Form/Form.constant";
import AnimatedLoader from "../../modules/ui/animatedLoader";

const ViewHome = ({ match }) => {
  const dispatch = useDispatch();
  const groupMemberHeaderToken = localStorage.getItem("GroupOrderMemberID");
  const group_order_member_id =
    groupMemberHeaderToken == "undefined"
      ? undefined
      : JSON.parse(localStorage.getItem("GroupOrderMemberID"));
  const avoidReload = useRef(false);
  const timeoutRef = useRef(null);
  const common = useSelector((state) => state.common);
  const [title, setTitle] = useState("CateredClub");
  const products = useSelector(
    (state) => state.products[match.params.slug_uuid]
  );
  const isJoin = true;
  const form = useSelector((state) => state.form);
  const modals = useSelector((store) => store.modal[match.params.slug_uuid]);
  const settings = common.settings?.[match.params?.slug_uuid];

  const product = modals?.modalProduct;
  const budget = settings?.budget;
  const groupOrders = common.groupOrders?.[match.params.slug_uuid];
  const { total_budget_with_topup, total_topup } =
    groupOrders?.group_order_members?.find(
      (item) => item.member_id == settings?.auth?.member_id
    ) || {};
  const balanceTopUpAmount =
    (total_budget_with_topup || 0) - groupOrders?.per_person_budget?.cents > 0
      ? (total_budget_with_topup || 0) - groupOrders?.per_person_budget?.cents
      : 0;
  const calculateExceededBudget =
    (budget + balanceTopUpAmount - product?.total_price_cents) / 100;

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("GroupOrderMemberID");
    };

    window.addEventListener("beforeunload", clearLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
      clearLocalStorage();
    };
  }, []);

  const onFocusPage = () => {
    if (
      settings?.isJoin &&
      settings?.auth?.group_order_token !== localStorage.getItem("token")
    ) {
      localStorage.setItem("token", settings?.auth?.group_order_token);
      window.location.reload();
    }
  };
  window.addEventListener("focus", onFocusPage);

  let fetchPresets = () => {
    !modals &&
      dispatch({ type: NEW_USER_MODAL, payload: match.params.slug_uuid });
    !products &&
      dispatch({ type: NEW_USER_PRODUCTS, payload: match.params.slug_uuid });
    dispatch({ type: FETCH_GROUP_ORDER, payload: match.params });
    onFocusPage();
    const clearDate = localStorage.getItem("clearDate");
    if (
      common?.groupOrders &&
      (!clearDate || +clearDate < new Date().valueOf() - 604800000)
    ) {
      let arrOldUuid = Object.keys(common?.groupOrders)
        .map(
          (item) =>
            common?.groupOrders[item]?.delivery_date_time > 0 &&
            new Date(common?.groupOrders[item]?.delivery_date_time * 1000) <
              new Date() &&
            match.params?.slug_uuid !== item &&
            item
        )
        .filter((i) => i);
      dispatch({ type: COMMON_CLEAR_OLD_GO, payload: arrOldUuid });
      dispatch({ type: MODAL_CLEAR_OLD_GO, payload: arrOldUuid });
      dispatch({ type: PRODUCTS_CLEAR_OLD_GO, payload: arrOldUuid });
      dispatch({ type: FORM_CLEAR_OLD_GO, payload: arrOldUuid });
      localStorage.setItem("clearDate", new Date().valueOf());
    }
  };
  useEffect(fetchPresets, []);

  const forceOpenModal = (name) => {
    dispatch({ type: NEW_USER_MODAL, payload: match.params.slug_uuid });
    dispatch({ type: CLOSE_MODAL_AUTH, payload: match.params.slug_uuid });
    dispatch({
      type: MODAL_TOGGLE,
      payload: { data: name, user_id: match.params.slug_uuid, boolean: true },
    });
  };

  const LoadGroupOrder = () => {
    const status = groupOrders?.status;
    modals?.isModalOrderEndCompleted &&
      dispatch({
        type: MODAL_TOGGLE,
        payload: {
          data: "isModalOrderEndCompleted",
          user_id: match.params.slug_uuid,
          boolean: false,
        },
      });
    modals?.isModalOrderEndCancelled &&
      dispatch({
        type: MODAL_TOGGLE,
        payload: {
          data: "isModalOrderEndCancelled",
          user_id: match.params.slug_uuid,
          boolean: false,
        },
      });
    (status === "wait_for_delivery" ||
      status === "waiting_for_delivery" ||
      status === "delivered" ||
      status === "completed") &&
      forceOpenModal("isModalOrderEndCompleted");
    (status === "cancelled" || status === "declined") &&
      forceOpenModal("isModalOrderEndCancelled");
  };
  useEffect(LoadGroupOrder, [groupOrders]);

  if (groupOrders && "CateredClub" === title) {
    setTitle(
      `${groupOrders?.name} - ${
        groupOrders?.delivery_date_time
          ? format(
              new Date(groupOrders.delivery_date_time * 1000),
              "EEEE, MMMM do, h:mm aaa"
            )
          : null
      }`
    );
  }
  const groupOrdersMembers = () => {
    if (
      settings &&
      group_order_member_id != null &&
      group_order_member_id != "undefined"
    ) {
      dispatch({ type: COMMON_GET_MY_MEMBER, payload: match.params.slug_uuid });
      dispatch({
        type: FETCH_OLD_MY_PRODUCTS,
        payload: {
          id: settings?.auth?.id,
          menu: common?.groupOrders?.[match.params.slug_uuid]?.preset
            ?.chefs?.[0].menus,
          user_id: match.params?.slug_uuid,
        },
      });
      dispatch({
        type: FETCH_GROUP_ORDER_ITEMS,
        payload: {
          data: groupOrders?.id,
          user_id: match.params.slug_uuid,
        },
      });
    }
  };
  useEffect(groupOrdersMembers, [settings?.isJoin, group_order_member_id]);

  let changeProductsOrder = () => {
    let budget = groupOrders ? +groupOrders.per_person_budget?.cents : 0;
    products?.myProducts &&
      products.myProducts.map((item) => (budget -= +item.total_price_cents));
    settings?.budget !== budget &&
      dispatch({
        type: COMMON_CHANGE_BUDGET,
        payload: { data: budget, user_id: match.params.slug_uuid },
      });
  };
  useEffect(changeProductsOrder, [products?.myProducts, settings]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (!avoidReload.current && calculateExceededBudget < 0) {
        toast("Budget limit reached; extra items must be paid for by you", {
          type: "warning",
        });
        avoidReload.current = true;
      } else if (calculateExceededBudget >= 0) {
        avoidReload.current = false;
      }
    });
    return () => clearTimeout(timeoutRef.current);
  }, [calculateExceededBudget, avoidReload]);
  if (group_order_member_id === undefined) {
    return <Redirect to={`/grouporder-dashboard/${groupOrders?.creator_id}`} />;
  }
  return (
    <>
      <WidgetHeader
        user_id={match.params.slug_uuid}
        isJoin={isJoin}
        auth={settings?.auth}
        creator_id={groupOrders?.creator_id}
      />

      {/* <div className="container share-wrapper  group-order-container">
        <div className="share">
          <Link to={`/grouporder-dashboard/${groupOrders?.creator_id}`}>
            Go Back To Group Order Dashboard
          </Link>
        </div>
      </div> */}
      <AnimatedLoader isLoading={products?.isLoading} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta id="meta-description" name="description" content={title} />
      </Helmet>
      <ToastContainer progressClassName="fancy-progress-bar" />
      <Modals
        calculateExceededBudget={calculateExceededBudget}
        balanceTopUpAmount={balanceTopUpAmount}
        modals={modals}
        form={form}
        products={products}
        slug_uuid={match.params.slug_uuid}
        common={common}
      />
      <Invitation
        user_id={match.params.slug_uuid}
        isJoin={isJoin}
        groupOrders={groupOrders}
        settings={settings}
        balanceTopUpAmount={balanceTopUpAmount}
        total_topup={total_topup}
      />
      <MyOrder
        slug_uuid={match.params.slug_uuid}
        settings={settings}
        currency={groupOrders?.tax_info?.currency}
        joined_member_count={groupOrders?.joined_member_count}
        head_count={groupOrders?.head_count}
        balanceTopUpAmount={balanceTopUpAmount}
        total_topup={total_topup}
        isJoin={isJoin}
        products={products}
        group_order_member={groupOrders?.group_order_member}
      />
      <TopTabs
        auth_id={settings?.auth?.id}
        user_id={match.params.slug_uuid}
        id={groupOrders?.id}
        joined_member={groupOrders?.joined_member_count}
        currency={groupOrders?.tax_info?.currency}
        isJoin={isJoin}
        products={products}
        groupOrdersMembers={groupOrders?.group_order_members}
        groupOrdersItems={groupOrders?.group_order_items}
        presets={groupOrders?.preset}
        settings={settings}
      />
      <WidgetFooter />
    </>
  );
};

export default withRouter(ViewHome);
