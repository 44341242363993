import { combineReducers } from 'redux';
import form from "./Form/Form.reducer";
import modal from "./Modal/Modal.reducer";
import common from './Common/Common.reducer';
import products from './Products/Products.reducer';
import groupOrderDashboard from './GroupOrder/GroupOrder.reducer';

export const rootReducer = combineReducers({
    common,
    form,
    modal,
    products,
    groupOrderDashboard
});
