import { call, fork, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../shared/services/ApiService";
import { common, form } from "../../shared/utils/UtilSaga";
import {
  FORM_APPLY_CODE,
  FORM_APPLY_CODE_SUCCESS,
  FORM_EDIT_PROFILE,
  FORM_MODAL_AUTH,
  FORM_VERIFICATION_CODE,
  GROUP_ORDER_DASHBOARD_AUTH,
} from "./Form.constant";
import {
  COMMON_CHANGE_SETTINGS,
  COMMON_GET_MY_MEMBER,
  COMMON_USER_JOIN,
} from "../Common/Common.constant";
import { CLOSE_MODAL_AUTH, MODAL_TOGGLE } from "../Modal/Modal.constant";

function* workerVerificationCode(action) {
  const { user_id, order_deadline, ...value } = action.payload.values;
  yield call(ApiService.form.verification, value);
  yield put({
    type: MODAL_TOGGLE,
    payload: { data: "isModalConfirmation", user_id: user_id },
  });
  yield put({ type: COMMON_USER_JOIN, payload: user_id });
}
function* watchVerificationCode() {
  yield takeLatest(FORM_VERIFICATION_CODE, form(workerVerificationCode));
}

function* workerModalAuth(action) {
  let { image_base64, phone_logIn, user_id, id, ...value } =
    action.payload.values;
  let data;
  if (phone_logIn) {
    data = yield call(ApiService.form.logIn, { id, phone: phone_logIn });
  } else {
    data = yield call(ApiService.form.addToken, { id, ...value });
  }
  yield put({
    type: MODAL_TOGGLE,
    payload: { data: "isModalConfirmation", user_id: user_id },
  });
  yield put({
    type: COMMON_CHANGE_SETTINGS,
    payload: { data: { auth: { ...value, ...data } }, user_id: user_id },
  });
  localStorage.setItem("token", data?.group_order_token);
  if (phone_logIn) {
    yield put({ type: COMMON_USER_JOIN, payload: user_id });
  }
  yield put({ type: CLOSE_MODAL_AUTH, payload: user_id });
}
function* watchModalAuth() {
  yield takeLatest(FORM_MODAL_AUTH, form(workerModalAuth));
}
function* workerGroupOrderDashboardAuth(action) {
  let { image_base64, phone_logIn, user_id, id, email_login, ...value } =
    action.payload.values;
  let data;
  if (phone_logIn || email_login) {
    data = yield call(ApiService.form.dashboardLogin, {
      phone: phone_logIn,
      email: email_login,
    });
  } else {
    data = yield call(ApiService.form.signUpDashboard, { id, ...value });
  }
  yield put({
    type: MODAL_TOGGLE,
    payload: { data: "isModalConfirmation", user_id },
  });
  yield put({
    type: COMMON_CHANGE_SETTINGS,
    payload: { data: { auth: { ...value, ...data } }, user_id },
  });
  localStorage.setItem("token", data?.auth_token);
  if (phone_logIn) {
    yield put({ type: COMMON_USER_JOIN, payload: user_id });
  }
  yield put({ type: CLOSE_MODAL_AUTH, payload: user_id });
}
function* watchGroupOrderDashboardAuth() {
  yield takeLatest(
    GROUP_ORDER_DASHBOARD_AUTH,
    form(workerGroupOrderDashboardAuth)
  );
}

function* workerApplyCode(action) {
  // yield call(ApiService.form.addTeamOrderEmail, action.payload);
  yield put({ type: FORM_APPLY_CODE_SUCCESS, payload: action.payload });
}
function* watchApplyCode() {
  yield takeLatest(FORM_APPLY_CODE, common(workerApplyCode));
}

function* workerEditProfile(action) {
  let { image_base64, user_id, auth, profile_image, ...value } =
    action.payload.values;
  let nevProfile_image = image_base64 ? { profile_image: profile_image } : {};

  yield call(ApiService.form.editProfile, { ...value, ...nevProfile_image });
  yield put({ type: COMMON_GET_MY_MEMBER, payload: user_id });
  yield put({
    type: MODAL_TOGGLE,
    payload: { data: "isModalEditProfile", user_id: user_id, boolean: false },
  });
}
function* watchEditProfile() {
  yield takeLatest(FORM_EDIT_PROFILE, common(workerEditProfile));
}

const formWatchers = [
  fork(watchEditProfile),
  fork(watchApplyCode),
  fork(watchModalAuth),
  fork(watchGroupOrderDashboardAuth),
  fork(watchVerificationCode),
];

export default formWatchers;
