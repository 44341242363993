export const FORM_INVALID_FIELDS = '@form/INVALID_FIELDS';
export const FORM_RESET_ERRORS = '@form/FORM_RESET_ERRORS';

export const FORM_APPLY_CODE = '@form/FORM_APPLY_CODE';
export const FORM_APPLY_CODE_SUCCESS = '@form/FORM_APPLY_CODE_SUCCESS';

export const FORM_EDIT_PROFILE = '@form/FORM_EDIT_PROFILE';

export const FORM_CLEAR_OLD_GO = '@form/FORM_CLEAR_OLD_GO';
export const FORM_MODAL_AUTH = '@form/FORM_MODAL_AUTH';
export const GROUP_ORDER_DASHBOARD_AUTH = '@form/GROUP_ORDER_DASHBOARD_AUTH';
export const FORM_CREATE_GO_SET_VAL = '@form/FORM_CREATE_GO_SET_VAL';
export const FORM_CREATE_GO_DELETE_VAL = '@form/FORM_CREATE_GO_DELETE_VAL';
export const FORM_VERIFICATION_CODE = '@form/FORM_VERIFICATION_CODE';

