import HttpService from "../HttpService";
export const fetchGroupOrderDashboard = async ({ id, filter }) => {
  const res = await HttpService({
    method: "GET",
    url: `/api/v2/${id}/group_orders${filter ? `?filter=${filter}` : ""}`,
  });
  return res.data;
};
export const joinedGroupOrderApi = async ({id}) => {
  const res = await HttpService({
    method: "POST",
    url: `/api/v2/group_order_members/join`,
    data:{group_order_id:id}
  });
  return res.data;
};
