export const COMMON_EXCEPTION = '@common/EXCEPTION';

export const COMMON_CLEAR_OLD_GO = '@common/COMMON_CLEAR_OLD_GO';
export const COMMON_ERROR = '@common/COMMON_ERROR';
export const COMMON_TOGGLE_LOADING = '@common/COMMON_TOGGLE_LOADING';
export const COMMON_USER_JOIN = '@common/COMMON_USER_JOIN';
export const COMMON_CLEAR_CREATE_GROUP_ORDER = '@common/COMMON_CLEAR_CREATE_GROUP_ORDER';
export const COMMON_CHANGE_SETTINGS = '@common/COMMON_CHANGE_SETTINGS';
export const COMMON_CHANGE_BUDGET = '@common/COMMON_CHANGE_BUDGET';
export const CLEAR_PAYMENT_INFO = '@common/CLEAR_PAYMENT_INFO';
export const COMMON_PAYMENT_COMPLETE = '@common/COMMON_PAYMENT_COMPLETE';
export const CANCEL_GROUP_ORDER = '@common/CANCEL_GROUP_ORDER';

export const COMMON_RESEND_VERIFICATION = '@common/COMMON_RESEND_VERIFICATION';
export const COMMON_RESEND_VERIFICATION_SUCCESS = '@common/COMMON_RESEND_VERIFICATION_SUCCESS';

export const COMMON_GET_MY_MEMBER = '@common/COMMON_GET_MY_MEMBER';
export const COMMON_GET_MY_MEMBER_SUCCESS = '@common/COMMON_GET_MY_MEMBER_SUCCESS';

export const FETCH_GROUP_ORDER = '@common/FETCH_GROUP_ORDER';
export const FETCH_GROUP_ORDER_SUCCESS = '@common/FETCH_GROUP_ORDER_SUCCESS';

export const FETCH_GROUP_ORDER_ITEMS = '@common/FETCH_GROUP_ORDER_ITEMS';
export const FETCH_GROUP_ORDER_ITEMS_SUCCESS = '@common/FETCH_GROUP_ORDER_ITEMS_SUCCESS';

export const CREATE_PAYMENT_INTENT_GROUP_ORDER = '@common/CREATE_PAYMENT_INTENT_GROUP_ORDER';
export const CREATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS = '@common/CREATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS';

export const UPDATE_PAYMENT_INTENT_GROUP_ORDER = '@common/UPDATE_PAYMENT_INTENT_GROUP_ORDER';
export const UPDATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS = '@common/UPDATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS';

export const PAYMENT_OLD_CARD_INTENT = '@common/PAYMENT_OLD_CARD_INTENT';

export const PAYMENT_COMPLETE_GROUP_ORDER = '@common/PAYMENT_COMPLETE_GROUP_ORDER';
export const PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS = '@common/PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS';

export const FETCH_UPLOAD_CSV = '@common/FETCH_UPLOAD_CSV';
export const SET_LOADER_UPLOAD_CSV = '@common/SET_LOADER_UPLOAD_CSV';